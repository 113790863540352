import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";


const Collections = ({ match }) => (
    <div className="app-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/arrears`} />
            <Route path={`${match.url}/assignments`} component={asyncComponent(() => import('./routes/CollectionsAssignment.js'))} />
            <Route path={`${match.url}/:master`} component={asyncComponent(() => import("./routes/pipelines"))} />
            {/*<Route path={`${match.url}/:master`} component={asyncComponent(() => import("./routes/batches"))}/>*/}
        </Switch>
    </div>
);

export default Collections;
